@import '../../styles/customMediaQueries.css';

.root {}

.pageRoot {
  scroll-behavior: smooth;
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.contentWrapperRoot {
  margin-top: 0px;

  @media (--viewportMedium) {
    margin-top: 88px;
  }

  @media (--viewportLarge) {
    margin-top: 100px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 0;
  font-weight: var(--fontWeightMedium);
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */
  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorBlueTextDark);
  z-index: 1;
  /* bring on top of mobile image */
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;
  /* Colors */
  background-color: var(--colorWhite);
  /* Borders */
  border: none;
  border-radius: var(--borderRadius);
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 360px);
    flex-basis: 60%;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
    padding: 0 30px;
  }

  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 40%;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 20px auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: 1280px;
    padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto;
  }

  @media (--viewportLarge) and (max-width: 1200px) {
    width: 75%;
    padding: 64px 36px 0 36px;
    margin: 0 20px 30px 30px;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  background-color: #fff;

  @media (--viewportLarge) {
    display: block;
  }

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  color: var(--colorTextBlack);
  /* Layout */
  width: 100%;
  margin: 10px 0 0 0px;
  padding: 0 10px;

  @media (--viewportMedium) {
    font-size: 32px;
    padding: 0;
    margin: 0 0 24px 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;
  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;

  /* Hide the large avatar from the smaller screens */
  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;
  /* Borders */
  border: none;
  /* Colors */
  color: var(--marketplaceColor);
  /* Text size should be inherited */
  text-decoration: none;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);
  color: var(--colorTextBlack);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    font-size: 16px;
    white-space: nowrap;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 4px 10px;
  margin: 10px 22px 10px 0;

  @media (--viewportMedium) {
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 4px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;
  width: 100vw;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    width: inherit;
  }
}

.sectionWeatherForecast,
.sectionRiverData,
.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }

  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.sectionWeatherForecast,
.sectionRiverData {
  width: 100vw;

  @media (--viewportMedium) {
    width: inherit;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: 400px;
  width: 100%;
  /* Static map: dimensions are 640px */
  max-width: 724px;
  max-height: 724px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 50vh;
    width: 100%;
    max-width: 100vw;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.price {
  composes: h4 from global;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorTextBlack);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-size: 13px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorTextBlack);
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    font-size: 16px;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  padding: 4px 10px;
  margin: 10px 22px 10px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}

.priceContainer > strong {
  color: var(--colorTextBlack);
}

.locationAddressContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  gap: 3px;
  padding: 0 10px;

  @media (--viewportMedium) {
    padding: 0;
    gap: 10px;
  }
}

.locationAddressContainer > svg {
  min-width: 18px;
}

.locationAddressContainer > .locationAddress:hover {
  text-decoration: none;
  color: #666;
}

.locationAddressContainer > .locationAddress {
  margin: 0;
  color: #777e82;
  font-size: 12px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    font-size: 18px;
  }

  @media (--viewportLarge) {
    font-size: 22px;
  }

  @media (--viewportXLarge) {
    font-size: 24px;
  }
}

.locationAddress > .locationIcon {
  margin-right: 5px;
}

.productReviewsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 0 10px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.productReviewRating {
  margin-bottom: 18px;
}

.reviewsText a {
  font-size: 14px;
  color: #777e82;
  font-weight: var(--fontWeightRegular);

  &:hover {
    text-decoration: none;
    color: var(--colorBlueButton);
  }
}

.reviewsTextv2 {
  font-size: 14px;
  color: var(--marketplaceColor);
  margin-top: 6px;
  padding: 0 10px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.listingNavContainer {
  display: none;
  scroll-behavior: smooth;

  @media (--viewportMedium) {
    display: block;
    flex-grow: 1;
    margin: 24px 0px 18px 0;
  }

  @media (--viewportLarge) {
    margin: 34px 0px 18px 0;
  }

  @media (--viewportLarge) {
    margin: 54px 0px 18px 0;
  }
}

.listingNav {
  flex-direction: column;
  align-items: center;
  gap: 28px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.listingNav li {
  transition: var(--transitionStyleButton);
  display: inline-block;
  font-weight: var(--fontWeightRegular);
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0;
  flex-shrink: 0;
  height: 100%;
  padding: 0 20px 12px 0;
  margin: 0;


  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: none;
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.listingNav li a {
  color: #656565;
  text-align: center;

  &:hover {
    color: #0094ff;
    text-decoration: none;
    border-bottom: 2px solid var(--colorBlueButton);
    padding: 0 0 24px 0;
  }
}

.bookmarkContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.likeButton {
  border-radius: 36px;
  width: 100%;
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  width: 222px;
  min-height: 45px;
}

.shareContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.knowBeforeGoContainer {
  max-width: 1024px;
  width: 100%;
  background: var(--colorBlueLight);
  border-radius: 8px;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 24px;
  scroll-margin: 28px;
}

.knowBeforeGoContainer > .resources {
  color: var(--colorBlueButton);
  font-size: 12px;
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  line-height: normal;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
}

.knowBeforeGoContainer > .title {
  color: var(--colorTextBlack);
  font-size: 24px;
  font-style: normal;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  margin: 2 0 14px 0;
}

.knowBeforeGoContainer > .resourcesMessage {
  color: var(--colorTextBlack);
  font-size: 16px;
  margin: 0;
  font-style: normal;
  font-weight: var(--fontWeightRegular);
  line-height: normal;
}

.knowBeforeGoContainer a {
  color: #0094ff !important;
}

.hrStyle {
  width: 100%;
  border: 0.5px solid #a6bac9;
}

.missionMessage {
  padding: 0 10px;
}

.missionMessage p {
  padding-bottom: 20px;
}

.missionMessage p {
  color: var(--colorTextBlack);
  font-size: 13px;
  font-style: italic;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
  border-top: solid 1px #e8e8e8;

  @media (--viewportMedium) {
    line-height: 1.4em;
  }
}

.listingWeatherDataBox {
  display: flex;
  background: #f9f9f9;
  padding: 20px 0px;
  margin: 20px 0px;
  border: solid 1px #dfdfdf;
  justify-content: space-around;
}

.weatherDay {
  text-align: center;

  @media (--viewportMedium) {
    padding: 5px;
  }
}

.weatherIcon {
  max-width: 50px;
  filter: invert(49%) sepia(89%) saturate(4038%) hue-rotate(184deg) brightness(102%) contrast(102%);
}

.weatherParagraph {
  font-size: 12px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.weatherParagraph strong {
  color: var(--colorTextBlack);

  @media (--viewportMedium) {
    font-weight: var(--fontWeightBold);
  }
}

.weatherParagraphItalic {
  font-size: 12px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.bookmarkedSpan {
  font-size: 15px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  color: #888;
}

.listingOverview {
  padding: 0 10px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.listingOverview p {
  color: var(--colorTextBlack);
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.videoDiv {
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  top: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.videoPlayerWrapper {
  width: auto;

  @media (--viewportMedium) {
    width: 500px;
    max-width: none;
    max-height: none;
  }
}

.posterImage {
  margin: 0 auto;
  display: block;
  object-fit: cover;
  opacity: 1;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
    max-width: 500px;
  }
}

.videoStyle {
  opacity: 1;
  margin: 0 auto;
  width: 360px;
  height: 100%;

  @media (--viewportSmall) {
    width: 500px;
    height: 300px;
  }

  @media (--viewportMedium) {
    width: 500px;
    height: 300px;
  }
}

.showVideo {
  display: block !important;
}

.lodgingNotesContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  padding: 4px 10px;
  margin: 10px 22px 10px 0;
  width: 100%;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}

.lodgingNotesContainer > strong {
  max-width: 20%;
  color: var(--colorTextBlack);
}

.lodgingNotesContainer > .listingOverview {
  max-width: 80%;
}

.lodgingNotesContainer > .listingOverview p {
  margin: 0 !important;
  padding: 0 !important;
}

.richTextEditor {
  width: 100%;
}

.richTextEditor ol li,
.richTextEditor ul li {
  padding: 0;
  color: rgb(22, 24, 31);
  line-height: normal;
}

.richTextEditor ol,
.richTextEditor ul {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 15px;
}
